<template>
  <div class="content">
    <div class="box2">
      <div class="left"></div>
      <div class="right">
        <div class="right-title">时空大数据与云平台</div>
        <div class="right-box">
          <i></i>
          <span>高效的海量数据自动化实景三维建模</span>
          <div>
            通过超算中心及自主核心知识产权软件打造自主可控、高效优质的全空间三维建模一体化解决方案
          </div>
        </div>

        <div class="right-box">
          <i></i>
          <span>多源数据的融合与重构</span>
          <div>
            实现卫星影像、低空无人机、地面激光点云、行业专题数据等多源数据的融合与重构
          </div>
        </div>

        <div class="right-box">
          <i></i>
          <span>全域空间的一张图展示</span>
          <div>室内室外、地上地下一体化，实现全域空间的一张图展示</div>
        </div>

        <div class="right-box">
          <i></i>
          <span>高精度、无缝位置服务</span>
          <div>
            融合北斗、组合惯导的室内外一体化定位导航，实现空间位置与高精度地图的融合
          </div>
        </div>

        <div class="right-box">
          <i></i>
          <span>新型产业驱动</span>
          <div>
            VR、AR等新型产业核心平台
            数据银行：双向数据服务，用户本身也可称为生产人员，分享平台收益人工智能汇聚平台，基础应用工具+公众创新应用=众包人工智能服务
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 10%;
  .box2 {
    width: 100%;
    height: 700px;
    display: flex;
    margin: 30px 0 50px 0;
    padding: 50px 30px;
    background-color: #fff;
    .left {
      width: 50%;
      height: 100%;
      background: url("../../../../assets/image/cityImage/img2.png") no-repeat;
    }
    .right {
      width: 50%;
      padding: 7%;
      padding-top: 0;
      box-sizing: border-box;
      .right-title {
        font-size: 28px;
        font-weight: 600;
        margin: 30px 0;
        margin-top: 39px;
        color: #333333;
      }
      .right-box {
        margin: 30px 0;
        i {
          display: inline-block;
          width: 0.8vw;
          height: 0.8vw;
          position: absolute;
          background: #dedede;
          transform: translateX(-200%) translateY(60%) rotate(45deg);
        }
        span {
          font-size: 24px;
          color: #1864e3;
        }
        div {
          font-size: 16px;
          font-weight: normal;
          margin-top: 10px;
          color: #6b6b6b;
        }
      }
    }
  }
}
</style>